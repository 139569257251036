var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SlListView',{ref:"listView",attrs:{"total":_vm.page.total,"pageIndex":_vm.page.pageIndex,"pageSize":_vm.page.pageSize,"pageSizes":_vm.pageSizes},on:{"gotoPage":_vm.gotoPage}},[_c('div',{attrs:{"slot":"search"},slot:"search"},[_c('SlSearchForm',{ref:"searchForm",attrs:{"items":_vm.searchItems,"loading":_vm.loading},on:{"reset":_vm.reset,"search":function($event){return _vm.gotoPage(_vm.page.pageSize)}},model:{value:(_vm.formQuery),callback:function ($$v) {_vm.formQuery=$$v},expression:"formQuery"}})],1),_c('SlSwitchNav',{attrs:{"align":"right","navs":_vm.switchNavs,"default-active":_vm.switchActiveIndex},on:{"select":_vm.switchNav},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('span',{staticClass:"pdl-2rem"},[_c('SlButton',{staticClass:"mr-8px",attrs:{"type":"primary","boxShadow":"primary","loading":_vm.loading,"disabled":!_vm.canExcute},on:{"click":_vm.openDeliverDialog}},[_vm._v("发货")]),_c('SlButton',{staticClass:"mr-8px",attrs:{"type":"primary","boxShadow":"primary","loading":_vm.loading,"disabled":!_vm.canExcute},on:{"click":_vm.openPriceAuditDialog}},[_vm._v("成本复核")]),_c('SlButton',{attrs:{"type":"primary","boxShadow":"primary","loading":_vm.loading},on:{"click":_vm.exportPdf}},[_vm._v("导出")])],1)]},proxy:true},{key:"custom",fn:function(ref){
var tab = ref.tab;
return [_vm._v(_vm._s(tab.tabName)+"("+_vm._s(tab.count)+")")]}}])}),_c('SlCardTable',{ref:"cardTable",attrs:{"data":_vm.tableData,"columns":_vm.columns,"childName":"awaitShipmentItemVoList"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var row = ref.row;
return [_c('el-tag',{staticClass:"mr-8px align-center",class:{'purple-tag': row.orderProcessType === 1, 'success-tag': row.orderProcessType === 2},staticStyle:{"width":"54px"},attrs:{"effect":"plain"}},[_vm._v(_vm._s(row.orderProcessTypeName))]),(row.fastOrderFlag)?_c('el-tag',{staticClass:"mr-8px float-right",attrs:{"type":"danger"}},[_vm._v("快返单")]):_vm._e(),(row.needRfid)?_c('el-tag',{staticClass:"mr-8px float-right"},[_vm._v("RFID")]):_vm._e(),(_vm.columns.length > 0)?_c('ul',{staticClass:"default-header-content"},_vm._l((_vm.columns),function(propItem){return _c('li',{key:propItem.prop,staticClass:"mr-24px"},[_c('span',{staticClass:"header-prop-label mr-8px"},[_vm._v(_vm._s(propItem.label)+":")]),_c('span',{staticClass:"header-prop-value"},[_vm._v(_vm._s(row[propItem.prop]))])])}),0):_vm._e()]}},{key:"body",fn:function(ref){
var cardRow = ref.row;
return [_c('SlTable',{ref:"table",attrs:{"align":"left","border":false,"tableData":cardRow['awaitShipmentItemVoList'],"columns":_vm.childColumns,"operate":false,"tooltip":false,"isEmbedTable":true,"rowKey":"id","rowId":cardRow.id},on:{"selectRow":_vm.handleSelection},scopedSlots:_vm._u([{key:"warehouseName",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(_vm._s(row.warehouseName))]),(row.orderProcessType === 1)?_c('p',[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.showSupplierDetail(row)}}},[_vm._v(_vm._s(row.processPlantSupplierName))])],1):_vm._e()]}}],null,true),model:{value:(_vm.selectionsTree[cardRow.id]),callback:function ($$v) {_vm.$set(_vm.selectionsTree, cardRow.id, $$v)},expression:"selectionsTree[cardRow.id]"}})]}}])})],1),_c('DeliveryDialog',{ref:"deliveryDialog",on:{"submit":function($event){return _vm.gotoPage(_vm.page.pageSize)}}}),_c('SupplierDialog',_vm._b({},'SupplierDialog',_vm.supplier,false,true)),_c('PriceReviewDialog',{ref:"priceReview",on:{"submit":_vm.priceReviewSubmit}}),_c('SlDialog',{attrs:{"title":"提示","visible":_vm.btnMessageVisible},on:{"update:visible":function($event){_vm.btnMessageVisible=$event}},scopedSlots:_vm._u([{key:"bottom",fn:function(){return [_c('el-button',{on:{"click":function($event){_vm.btnMessageVisible = false}}},[_vm._v("关闭")])]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.btnMessageTip)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }